<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-flow"
        :title="
          'รายงานตัว ก่อนเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย รอบที่ : ' +
            periodassteachs.periodAssTeachTimes +
            ' / ' +
            periodassteachs.periodAssTeachYear
        "
        class="px-5 py-3"
      >
        <v-alert text color="error" class="mt-2">
          <h3 class="font14">
            ค้นหา
          </h3>
          <div class="font14">
            ให้ผู้ที่ได้รับการเรียกรายงานตัว ตำแหน่งครูผู้ช่วย กรอกข้อมูล
            รหัสบัตรประชาชน และ ปี พ.ศ. เกิด และทำการกดปุ่มค้นหา
            กรอกข้อมูลประกอบการรายงานตัว
          </div>

          <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

          <v-row align="center" no-gutters>
            <v-col class="grow">
              <v-icon color="error" size="40">mdi-alert</v-icon>
              ให้ดำเนินการก่อนวันรายงานตัว เลือกสถานศึกษา
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-alert>
        <v-row>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="mt_id_card"
              append-icon="mdi-id-card"
              label="ระบุ รหัสบัตรประชาชน"
              single-line
              hide-details
              dense
              filled
              class="mb-2"
            />
          </v-col>
          <v-col cols="12" md="5"
            ><v-text-field
              v-model="mt_brith_year"
              type="number"
              append-icon="mdi-calendar-month"
              label="ระบุ ปี พ.ศ. เกิด "
              single-line
              hide-details
              dense
              filled
              class="mb-2"
            />
          </v-col>
          <v-col cols="12" md="2">
            <div class="text-center">
              <v-btn @click="searchId()" color="info" outlined>
                <v-icon>mdi-magnify</v-icon> ค้นหา</v-btn
              >
            </div>
          </v-col>     
        </v-row>

        <v-row v-if="String(manage_assistant_teacher.mt_id_card).length>10">
          <v-col
            cols="12"
            md="12"
            v-if="
              manage_assistant_teacher.mt_status_report === 'confirm' ||
                manage_assistant_teacher.mt_status_report === 'approve'
            "
          >
            <div>
              <v-card max-width="80%" class="mx-auto" outlined>
                <div
                  v-if="manage_assistant_teacher.mt_status_report === 'approve'"
                  class="text-center"
                >
                  <h2>
                    <v-icon color="success" size="30">mdi-check-circle</v-icon>
                    ผ่านการตรวจสอบ
                  </h2>
                </div>

                <div class="mt-5 pa-5">
                  <v-img
                    v-if="
                      String(manage_assistant_teacher.mt_picprofile).length > 10
                    "
                    src="https://hrvec.ovec.go.th/empty.png"
                    height="300px"
                    contain
                    dark
                  >
                  </v-img>

                  <v-img
                    v-else
                    src="https://hrvec.ovec.go.th/empty.png"
                    height="300px"
                    contain
                    dark
                  ></v-img>
                  <div
                    class="text-center"
                    v-if="
                      String(manage_assistant_teacher.mt_picprofile).length > 10
                    "
                  ></div>

                  <div
                    class="mt-2"
                    v-if="
                      String(manage_assistant_teacher.mt_picprofile).length < 10
                    "
                  ></div>
                </div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-account-tie
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title class="font14">
                            {{
                              manage_assistant_teacher.mt_title_s +
                                manage_assistant_teacher.mt_frist_name +
                                " " +
                                manage_assistant_teacher.mt_last_name
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            manage_assistant_teacher.mt_id_card
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action></v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            manage_assistant_teacher.mt_brith_day +
                              "-" +
                              manage_assistant_teacher.mt_brith_month +
                              "-" +
                              manage_assistant_teacher.mt_brith_year
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >วัน-เดือน-ปี เกิด</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset></v-divider>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-phone
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title class="font14">
                            {{ manage_assistant_teacher.mt_tel_p }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >เบอร์โทรติดต่อ</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-account-cog
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{ manage_assistant_teacher.mt_id_branch }} :
                            {{
                              manage_assistant_teacher.name_branch
                            }}</v-list-item-title
                          >
                          <v-list-item-subtitle>สาขาวิชา</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action></v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{ manage_assistant_teacher.mt_ed_abb }}
                            {{
                              manage_assistant_teacher.mt_ed_name
                            }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >วุฒิการศึกษา</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset></v-divider>
                    </v-list>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr />
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="text-center font14 font-weight-bold">
                      สถานะ ใบอนุญาตประกอบวิชาชีพครู
                      <span
                        class="green--text"
                        v-if="
                          manage_assistant_teacher.mt_license_status === 'have'
                        "
                        >มีใบประกอบวิชาชีพครู</span
                      >
                      <span v-else class="warning--text"
                        >ไม่มีใบประกอบวิชาชีพ</span
                      >
                    </div>

                    <div
                      class="pa-2"
                      v-if="
                        manage_assistant_teacher.mt_license_status === 'have'
                      "
                    >
                      <div class="text-center font14">
                        เลขที่ใบอนุญาตประกอบวิชาชีพครู :

                        {{ manage_assistant_teacher.pt_licence }}
                      </div>

                      <div class="text-center font14">
                        วันที่ออกบัตร :

                        {{
                          manage_assistant_teacher.pt_ondate
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>

                      <div class="text-center font14">
                        วันที่บัตรหมดอายุ :

                        {{
                          manage_assistant_teacher.pt_enddate
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>

                      <div
                        class="text-center mb-5"
                        v-if="
                          String(manage_assistant_teacher.pt_licence_file)
                            .length > 10
                        "
                      >
                        <v-btn
                          outlined
                          color="info"
                          @click="viewpt_licence_file()"
                        >
                          <v-icon color="info" size="40"
                            >mdi-file-pdf-box</v-icon
                          >
                          ใบอนุญาตประกอบวิชาชีพครู
                        </v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="text-center">
                      <h3 class="pa-2">
                        สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ
                      </h3>
                      <div
                        v-if="
                          String(manage_assistant_teacher.mt_ed_file).length < 5
                        "
                      ></div>

                      <div v-else class="text-center">
                        <v-btn
                          outlined
                          color="info"
                          @click="viewed_file_file()"
                        >
                          <v-icon color="info" size="40"
                            >mdi-file-pdf-box</v-icon
                          >
                          สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ
                        </v-btn>
                      </div>
                    </div>

                    <div class="pa-5 text-center">
                      <h3 class="pa-2">
                        สำเนาใบรายงานผลการเรียน (Transcript)
                      </h3>
                      <div
                        v-if="
                          String(manage_assistant_teacher.mt_transcript)
                            .length < 5
                        "
                      ></div>

                      <div v-else class="text-center">
                        <v-btn
                          outlined
                          color="info"
                          @click="viewmt_transcriptfile()"
                        >
                          <v-icon color="info" size="40"
                            >mdi-file-pdf-box</v-icon
                          >
                          สำเนาใบรายงานผลการเรียน (Transcript)
                        </v-btn>
                      </div>
                    </div>

                    <div class="pa-5 text-center">
                      <h3 class="pa-2">
                        สำเนาบัตรประจำตัวประชาชน
                      </h3>
                      <div
                        v-if="
                          String(manage_assistant_teacher.mt_idcard_copy)
                            .length < 5
                        "
                      ></div>

                      <div v-else class="text-center">
                        <v-btn
                          outlined
                          color="info"
                          @click="viewmt_idcard_copyfile()"
                        >
                          <v-icon color="info" size="40"
                            >mdi-file-pdf-box</v-icon
                          >
                          สำเนาบัตรประจำตัวประชาชน
                        </v-btn>
                      </div>
                    </div>

                    <div
                      class="pa-5 text-center"
                      v-if="
                        String(manage_assistant_teacher.mt_change_name).length >
                          5
                      "
                    >
                      <h3 class="pa-2">
                        สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล
                        หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)
                      </h3>

                      <div class="text-center">
                        <v-btn
                          outlined
                          color="info"
                          @click="viewmt_change_namefile()"
                        >
                          <v-icon color="info" size="40"
                            >mdi-file-pdf-box</v-icon
                          >
                          สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล
                          หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" md="12" v-else>
            <div>
              <v-card max-width="80%" class="mx-auto" outlined>
                <h2 class="text-center red--text mt-3">
                  <v-icon color="error" size="30">mdi-alert</v-icon>
                  กรุณากรอกข้อมูลให้ถูกต้อง ครบถ้วน
                </h2>

                <div
                  v-if="manage_assistant_teacher.mt_status_report === 'cc'"
                  class="text-center red--text"
                >
                  <h3 class="text-center red--text">
                    {{ manage_assistant_teacher.mt_status_comment }}
                  </h3>
                  กรุณาตรวจสอบและทำรายการที่ถูกยกเลิกอีกครั้ง
                </div>

                <div class="mt-5 pa-5">
                  <v-img
                    v-if="
                      String(manage_assistant_teacher.mt_picprofile).length > 10
                    "
                    src="https://hrvec.ovec.go.th/empty.png"
                    height="300px"
                    contain
                    dark
                  >
                  </v-img>

                  <v-img
                    v-else
                    src="https://hrvec.ovec.go.th/empty.png"
                    height="300px"
                    contain
                    dark
                  ></v-img>
                  <div
                    class="text-center"
                    v-if="
                      String(manage_assistant_teacher.mt_picprofile).length > 10
                    "
                  >
                    <v-btn
                      @click="deletePic()"
                      class="mr-1 mt-1"
                      color="error"
                      outlined
                    >
                      <v-icon>mdi-delete</v-icon> ลบ</v-btn
                    >
                  </div>

                  <div
                    class="mt-2"
                    v-if="
                      String(manage_assistant_teacher.mt_picprofile).length < 10
                    "
                  >
                    <v-alert prominent type="error" outlined>
                      <v-row align="center">
                        <v-col class="grow">
                          <h3 class="pa-2">
                            รูปถ่าย 1 นิ้ว ไฟล์ JPG.
                          </h3>
                          <v-file-input
                            v-model="mt_picprofile"
                            accept="image/่jpg, image/jpeg"
                            name="mt_picprofile"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์รูปภาพ .jpg/jpeg"
                            placeholder="Select your files"
                            prepend-icon="mdi-paperclip"
                            outlined
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col class="shrink text-center">
                          <v-btn
                            @click="UpdateProfile()"
                            class=""
                            color="success"
                            outlined
                          >
                            <v-icon>mdi-content-save</v-icon> บันทึก</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-alert>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-account-tie
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title class="font14">
                            {{
                              manage_assistant_teacher.mt_title_s +
                                manage_assistant_teacher.mt_frist_name +
                                " " +
                                manage_assistant_teacher.mt_last_name
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            manage_assistant_teacher.mt_id_card
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action></v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            manage_assistant_teacher.mt_brith_day +
                              "-" +
                              manage_assistant_teacher.mt_brith_month +
                              "-" +
                              manage_assistant_teacher.mt_brith_year
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >วัน-เดือน-ปี เกิด</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset></v-divider>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-phone
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title class="font14">
                            <v-text-field
                              label="เบอร์โทรติดต่อ"
                              v-model="manage_assistant_teacher.mt_tel_p"
                              outlined
                            ></v-text-field>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-account-cog
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{ manage_assistant_teacher.mt_id_branch }} :
                            {{
                              manage_assistant_teacher.name_branch
                            }}</v-list-item-title
                          >
                          <v-list-item-subtitle>สาขาวิชา</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action></v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{ manage_assistant_teacher.mt_ed_abb }}
                            {{
                              manage_assistant_teacher.mt_ed_name
                            }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >วุฒิการศึกษา</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset></v-divider>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <div class="text-center">
                              <v-btn
                                @click="UpdateProfile()"
                                color="success"
                                outlined
                              >
                                <v-icon>mdi-content-save</v-icon> บันทึก
                              </v-btn>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr />
                  </v-col>

                  <v-col cols="12" md="12">
                    <div class="text-center font14">
                      <v-radio-group
                        class="ml-15"
                        v-model="manage_assistant_teacher.mt_license_status"
                        row
                      >
                        <template v-slot:label>
                          <div class="font14">
                            สถานะ ใบอนุญาตประกอบวิชาชีพครู
                            <strong> ระบุ</strong>
                          </div>
                        </template>
                        <v-radio value="have">
                          <template v-slot:label>
                            <div class="font14">
                              <strong class="success--text"
                                >มีใบประกอบวิชาชีพครู</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="donot">
                          <template v-slot:label>
                            <div class="font14">
                              <strong class="warning--text"
                                >ไม่มีใบประกอบวิชาชีพ</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="manage_assistant_teacher.mt_license_status === 'have'"
                  >
                    <div class="pa-2">
                      <div
                        v-if="
                          String(manage_assistant_teacher.pt_licence_file)
                            .length < 10
                        "
                      >
                        <v-file-input
                          v-model="pt_licence_file"
                          accept=".pdf"
                          name="pt_licence_file"
                          color="deep-purple accent-4"
                          counter
                          label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                          placeholder="Select your files"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :rules="rules"
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>

                        <div class="text-center mb-5">
                          <v-btn
                            @click="UpdateProfile()"
                            color="success"
                            outlined
                          >
                            <v-icon>mdi-content-save</v-icon> บันทึก
                          </v-btn>
                        </div>
                      </div>

                      <div
                        class="text-center mb-5"
                        v-if="
                          String(manage_assistant_teacher.pt_licence_file)
                            .length > 10
                        "
                      >
                        <v-btn
                          outlined
                          color="info"
                          @click="viewpt_licence_file()"
                        >
                          <v-icon color="info" size="40"
                            >mdi-file-pdf-box</v-icon
                          >
                          ใบอนุญาตประกอบวิชาชีพครู
                        </v-btn>

                        <v-btn
                          @click="deleteLicense()"
                          color="error"
                          class="ml-2"
                          outlined
                        >
                          <v-icon>mdi-delete</v-icon> ลบ
                        </v-btn>
                      </div>

                      <div>
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th width="60%" class="text-center">
                                รายการข้อมูล
                              </th>
                              <th class="text-center">
                                สถานะ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="pa-2">
                                <v-text-field
                                  prepend-icon="mdi-card-account-details"
                                  outlined
                                  label="เลขที่ใบอนุญาตประกอบวิชาชีพครู"
                                  v-model="manage_assistant_teacher.pt_licence"
                                ></v-text-field>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    String(manage_assistant_teacher.pt_licence)
                                      .length > 5
                                  "
                                >
                                  เลขที่ใบอนุญาตประกอบวิชาชีพครู <br />

                                  {{ manage_assistant_teacher.pt_licence }}
                                </div>
                                <div v-else>
                                  <v-icon size="40" color="error"
                                    >mdi-alert</v-icon
                                  ><br />
                                  กรุณากรอกข้อมูลให้ถูกต้อง
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td class="pa-2">
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        manage_assistant_teacher.pt_ondate
                                      "
                                      label="วันที่ออกบัตร"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="manage_assistant_teacher.pt_ondate"
                                    no-title
                                    scrollable
                                    locale="th"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    String(manage_assistant_teacher.pt_ondate)
                                      .length > 5
                                  "
                                >
                                  วันที่ออกบัตร <br />

                                  {{
                                    manage_assistant_teacher.pt_ondate
                                      | moment("add", "543 year")
                                      | moment("D MMMM YYYY")
                                  }}
                                </div>
                                <div v-else>
                                  <v-icon size="40" color="error"
                                    >mdi-alert</v-icon
                                  ><br />
                                  กรุณากรอกข้อมูลให้ถูกต้อง
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="pa-2">
                                <v-menu
                                  ref="menu1"
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        manage_assistant_teacher.pt_enddate
                                      "
                                      label="วันที่บัตรหมดอายุ"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="
                                      manage_assistant_teacher.pt_enddate
                                    "
                                    no-title
                                    scrollable
                                    locale="th"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu1 = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu1.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    String(manage_assistant_teacher.pt_enddate)
                                      .length > 5
                                  "
                                >
                                  วันที่บัตรหมดอายุ <br />

                                  {{
                                    manage_assistant_teacher.pt_enddate
                                      | moment("add", "543 year")
                                      | moment("D MMMM YYYY")
                                  }}
                                </div>
                                <div v-else>
                                  <v-icon size="40" color="error"
                                    >mdi-alert</v-icon
                                  ><br />
                                  กรุณากรอกข้อมูลให้ถูกต้อง
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>

                      <div class="text-center">
                        <v-btn
                          @click="UpdateProfile()"
                          color="success"
                          outlined
                        >
                          <v-icon>mdi-content-save</v-icon> บันทึก
                        </v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="12">
                    <div class="pa-2">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th width="70%" class="text-center">
                              File
                            </th>
                            <th class="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="pa-5">
                                <h3 class="pa-2">
                                  สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ
                                </h3>
                                <div
                                  v-if="
                                    String(manage_assistant_teacher.mt_ed_file)
                                      .length < 5
                                  "
                                >
                                  <v-file-input
                                    v-model="mt_ed_file"
                                    accept=".pdf"
                                    name="mt_ed_file"
                                    color="deep-purple accent-4"
                                    counter
                                    label="สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ .pdf"
                                    placeholder="Select your files"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :rules="rules"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </div>

                                <div v-else class="text-center">
                                  <v-btn
                                    outlined
                                    color="info"
                                    @click="viewed_file_file()"
                                  >
                                    <v-icon color="info" size="40"
                                      >mdi-file-pdf-box</v-icon
                                    >
                                    สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ
                                  </v-btn>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                v-if="
                                  String(manage_assistant_teacher.mt_ed_file)
                                    .length < 5
                                "
                              >
                                <v-btn
                                  @click="UpdateProfile()"
                                  color="success"
                                  outlined
                                >
                                  <v-icon>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                              </div>
                              <div v-else class="text-center">
                                <v-btn
                                  @click="deleteed_file()"
                                  color="error"
                                  class="ml-2"
                                  outlined
                                >
                                  <v-icon>mdi-delete</v-icon> ลบ
                                </v-btn>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="pa-5">
                                <h3 class="pa-2">
                                  สำเนาใบรายงานผลการเรียน (Transcript)
                                </h3>
                                <div
                                  v-if="
                                    String(
                                      manage_assistant_teacher.mt_transcript
                                    ).length < 5
                                  "
                                >
                                  <v-file-input
                                    v-model="mt_transcript"
                                    accept=".pdf"
                                    name="mt_transcript"
                                    color="deep-purple accent-4"
                                    counter
                                    label="สำเนาใบรายงานผลการเรียน (Transcript) .pdf"
                                    placeholder="Select your files"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :rules="rules"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </div>

                                <div v-else class="text-center">
                                  <v-btn
                                    outlined
                                    color="info"
                                    @click="viewmt_transcriptfile()"
                                  >
                                    <v-icon color="info" size="40"
                                      >mdi-file-pdf-box</v-icon
                                    >
                                    สำเนาใบรายงานผลการเรียน (Transcript)
                                  </v-btn>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                v-if="
                                  String(manage_assistant_teacher.mt_transcript)
                                    .length < 5
                                "
                              >
                                <v-btn
                                  @click="UpdateProfile()"
                                  color="success"
                                  outlined
                                >
                                  <v-icon>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                              </div>
                              <div v-else class="text-center">
                                <v-btn
                                  @click="deletmt_transcript()"
                                  color="error"
                                  class="ml-2"
                                  outlined
                                >
                                  <v-icon>mdi-delete</v-icon> ลบ
                                </v-btn>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="pa-5">
                                <h3 class="pa-2">
                                  สำเนาบัตรประจำตัวประชาชน
                                </h3>
                                <div
                                  v-if="
                                    String(
                                      manage_assistant_teacher.mt_idcard_copy
                                    ).length < 5
                                  "
                                >
                                  <v-file-input
                                    v-model="mt_idcard_copy"
                                    accept=".pdf"
                                    name="mt_idcard_copy"
                                    color="deep-purple accent-4"
                                    counter
                                    label="สำเนาบัตรประจำตัวประชาชน .pdf"
                                    placeholder="Select your files"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :rules="rules"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </div>

                                <div v-else class="text-center">
                                  <v-btn
                                    outlined
                                    color="info"
                                    @click="viewmt_idcard_copyfile()"
                                  >
                                    <v-icon color="info" size="40"
                                      >mdi-file-pdf-box</v-icon
                                    >
                                    สำเนาบัตรประจำตัวประชาชน
                                  </v-btn>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                v-if="
                                  String(
                                    manage_assistant_teacher.mt_idcard_copy
                                  ).length < 5
                                "
                              >
                                <v-btn
                                  @click="UpdateProfile()"
                                  color="success"
                                  outlined
                                >
                                  <v-icon>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                              </div>
                              <div v-else class="text-center">
                                <v-btn
                                  @click="deletmt_idcard_copy()"
                                  color="error"
                                  class="ml-2"
                                  outlined
                                >
                                  <v-icon>mdi-delete</v-icon> ลบ
                                </v-btn>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="pa-5">
                                <h3 class="pa-2">
                                  สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล
                                  หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)
                                </h3>
                                <div
                                  v-if="
                                    String(
                                      manage_assistant_teacher.mt_change_name
                                    ).length < 5
                                  "
                                >
                                  <v-file-input
                                    v-model="mt_change_name"
                                    accept=".pdf"
                                    name="mt_change_name"
                                    color="deep-purple accent-4"
                                    counter
                                    label="สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี) .pdf"
                                    placeholder="Select your files"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :rules="rules"
                                    :show-size="1000"
                                  >
                                    <template
                                      v-slot:selection="{ index, text }"
                                    >
                                      <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                      >
                                        {{ text }}
                                      </v-chip>

                                      <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                      >
                                        +{{ files.length - 2 }} File(s)
                                      </span>
                                    </template>
                                  </v-file-input>
                                </div>

                                <div v-else class="text-center">
                                  <v-btn
                                    outlined
                                    color="info"
                                    @click="viewmt_change_namefile()"
                                  >
                                    <v-icon color="info" size="40"
                                      >mdi-file-pdf-box</v-icon
                                    >
                                    สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุล
                                    หรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)
                                  </v-btn>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                v-if="
                                  String(
                                    manage_assistant_teacher.mt_change_name
                                  ).length < 5
                                "
                              >
                                <v-btn
                                  @click="UpdateProfile()"
                                  color="success"
                                  outlined
                                >
                                  <v-icon>mdi-content-save</v-icon> บันทึก
                                </v-btn>
                              </div>
                              <div v-else class="text-center">
                                <v-btn
                                  @click="deletmt_change_name()"
                                  color="error"
                                  class="ml-2"
                                  outlined
                                >
                                  <v-icon>mdi-delete</v-icon> ลบ
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>

                    <div class="text-center">
                      <v-btn
                        @click="UpdateProfileConfirm()"
                        large
                        color="success"
                      >
                        <v-icon>mdi-content-save</v-icon> ยืนยันรายการ
                        (บันทึกข้อมูล)</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </base-material-card>

      <!-- V-model pdflicenseteachdialog -->
      <v-dialog v-model="pdflicenseteachdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="
              '/HRvecLicenseTeach/' + manage_assistant_teacher.pt_licence_file
            "
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfeducationdialog -->
      <v-dialog v-model="pdfeducationdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HREducation/' + manage_assistant_teacher.mt_ed_file"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdftranscriptdialog -->
      <v-dialog v-model="pdftranscriptdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HREducation/' + manage_assistant_teacher.mt_transcript"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfidcarddialog -->
      <v-dialog v-model="pdfidcarddialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRIDcard/' + manage_assistant_teacher.mt_idcard_copy"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>

      <!-- V-model pdfidcardChangedialog -->
      <v-dialog v-model="pdfidcardChangedialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRIDcard/' + manage_assistant_teacher.mt_change_name"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { truncate } from "fs";
export default {
  name: "HRvecManageAssistantTeacher",

  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
    /*   mt_id_card: "1629900508192",
      mt_brith_year: "2542", */


      mt_id_card: "",
      mt_brith_year: "",



      periodassteachs: [],
      manage_assistant_teacher: [],
      searchData: {},
      mt_picprofile: null,
      pt_licence_file: null,
      mt_ed_file: null,
      mt_transcript: null,
      mt_idcard_copy: null,
      mt_change_name: null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB"
      ],
      pdflicenseteachdialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      pdfeducationdialog: false,
      pdftranscriptdialog: false,
      pdfidcarddialog: false,
      pdfidcardChangedialog: false
    };
  },

  async mounted() {
    await this.periodassteachQuery();
    if (this.periodassteachs.periodAssTeachEnable != "1")
      {
        Swal.fire({
            icon: "error",
            title: "ปิดระบบ",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(async result => {
            if (result.isConfirmed) {
              this.$router.push("/");
            }
          });
      }else{
        
      }
  },

  methods: {
    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachID: "103"
      });
      this.periodassteachs = result.data;
    },

    async searchId() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher_fastq.php", {
          ApiKey: this.ApiKey,
          mt_id_card: this.mt_id_card,
          mt_brith_year: this.mt_brith_year,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teacher = result.data;
      (result.data)
    },

    async UpdateProfile() {
      this.manage_assistant_teacher.ApiKey = this.ApiKey;
      let uploaded = null;
      let result_pic = "";
      if (this.mt_picprofile != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacher.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "users.jpg";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_picprofile);
        formData.append("filename", "../HRvecfiles/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacher.mt_picprofile = "";
        this.manage_assistant_teacher.mt_picprofile = filename;
        uploaded = true;
      }

      if (this.pt_licence_file != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacher.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "teach.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.pt_licence_file);
        formData.append("filename", "../HRvecLicenseTeach/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacher.pt_licence_file = "";
        this.manage_assistant_teacher.pt_licence_file = filename;
        uploaded = true;
      }

      if (this.mt_ed_file != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacher.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "ed.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_ed_file);
        formData.append("filename", "../HREducation/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacher.mt_ed_file = "";
        this.manage_assistant_teacher.mt_ed_file = filename;
        uploaded = true;
      }

      if (this.mt_transcript != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacher.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "transcript.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_transcript);
        formData.append("filename", "../HREducation/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacher.mt_transcript = "";
        this.manage_assistant_teacher.mt_transcript = filename;
        uploaded = true;
      }

      if (this.mt_idcard_copy != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacher.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "id.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_idcard_copy);
        formData.append("filename", "../HRIDcard/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacher.mt_idcard_copy = "";
        this.manage_assistant_teacher.mt_idcard_copy = filename;
        uploaded = true;
      }

      if (this.mt_change_name != null) {
        let formData = new FormData();
        let filename =
          this.manage_assistant_teacher.mt_id_card +
          "." +
          this.time_stamp +
          "." +
          "idchange.pdf";
        formData.append("ApiKey", this.ApiKey);
        formData.append("file", this.mt_change_name);
        formData.append("filename", "../HRIDcard/" + filename);

        result_pic = await this.$http.post("uploadfile.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.manage_assistant_teacher.mt_change_name = "";
        this.manage_assistant_teacher.mt_change_name = filename;
        uploaded = true;
      }

      let result_up = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.manage_assistant_teacher
      );
      if (result_up.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.searchId();
    },

    async UpdateProfileConfirm() {
      Swal.fire({
        title: "ยืนยันรายการ",
        text:
          "หากกดยืนยัน จะไม่สามารถแก้ไขรายการใดๆ ได้แล้ว กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          this.manage_assistant_teacher.mt_status_report = "confirm";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },

    async deleteLicense() {
      Swal.fire({
        title: "ยืนยันการลบ ใบอนุญาตประกอบวิชาชีพครู",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseTeach/" +
              this.manage_assistant_teacher.pt_licence_file
          });

          this.manage_assistant_teacher.pt_licence_file = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },
    async deletePic() {
      Swal.fire({
        title: "ยืนยันการลบ รูปถ่าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" + this.manage_assistant_teacher.mt_picprofile
          });

          this.manage_assistant_teacher.mt_picprofile = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },

    async deleteed_file() {
      Swal.fire({
        title: "ยืนยันการลบ สำเนาปริญญาบัตร หรือหนังสือรับรองคุณวุฒิ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HREducation/" + this.manage_assistant_teacher.mt_ed_file
          });

          this.manage_assistant_teacher.mt_ed_file = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },

    async deletmt_transcript() {
      Swal.fire({
        title: "ยืนยันการลบ สำเนาใบรายงานผลการเรียน (Transcript)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HREducation/" + this.manage_assistant_teacher.mt_transcript
          });

          this.manage_assistant_teacher.mt_transcript = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },

    async deletmt_idcard_copy() {
      Swal.fire({
        title: "ยืนยันการลบ สำเนาบัตรประจำตัวประชาชน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRIDcard/" + this.manage_assistant_teacher.mt_idcard_copy
          });

          this.manage_assistant_teacher.mt_idcard_copy = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },

    async deletmt_change_name() {
      Swal.fire({
        title:
          "ยืนยันการลบ สำเนาเอกสารการเปลี่ยนแปลงชื่อ - สกุลหรือสำเนาทะเบียนสมรส/อย่า (ถ้ามี)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.manage_assistant_teacher.ApiKey = this.ApiKey;
          let result_del = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRIDcard/" + this.manage_assistant_teacher.mt_change_name
          });

          this.manage_assistant_teacher.mt_change_name = "";

          let result_up = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.manage_assistant_teacher
          );
          if (result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.searchId();
        }
      });
    },

    async viewpt_licence_file() {
      this.pdflicenseteachdialog = true;
    },

    async viewed_file_file() {
      this.pdfeducationdialog = true;
    },

    async viewmt_transcriptfile() {
      this.pdftranscriptdialog = true;
    },

    async viewmt_idcard_copyfile() {
      this.pdfidcarddialog = true;
    },

    async viewmt_change_namefile() {
      this.pdfidcardChangedialog = true;
    },

    row_classes(item) {
      let whoBooking = String(item.whoBooking);
      if (whoBooking.length > 4) {
        return "green";
      }
    }
  },

  computed: {
    color() {
      return "light-blue-darken-4";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    },
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    }
  }
};
</script>

<style scoped>
.hide-idcard-number {
  display: inline-block;
  font-family: monospace;
  position: relative;
}

.hide-idcard-number::after {
  content: "XXXXXXXX";
  background: white;
  position: absolute;
  left: 0;
}
.font14 {
  font-size: 14pt;
}
</style>
